import React, { Component } from 'react';
import injectsheet from 'react-jss';
import StudentsList from '../data/student'
import { 
    Row,
    Col,
 } from "reactstrap";
 import Zoom from 'react-reveal/Zoom';

const styles = {
    achivement: {
        position: 'relative',
        '& .title':{
            '& h2':{
                color: '#91230c',
                textAlign: 'left',
                marginBottom: '3%',
                position: 'static',
                '@media screen and (max-width: 767px)':{
                    fontSize: '1.4rem',
                },
            }
        },
        '& h1':{
            marginTop: '6%',
            marginBottom: '2%',
            color: '#ea9044',
            '@media screen and (max-width: 767px)':{
                fontSize: '1.6rem',
            },
            '@media screen and (max-width: 1068px)':{
                marginTop: '8%',
               },
               '@media screen and (max-width: 868px)':{
                 marginTop: '10%',
                },
                '@media screen and (max-width: 556px)':{
                    marginTop: '20%',
                },
                '@media screen and (max-width: 468px)':{
                 marginTop: '25%',
                },
        }, 
        '& .cardWrapper':{
            marginBottom: '3%',
        },
        '& .studentCard':{
            position: 'relative',
            boxShadow: '2px 1px 5px 1px #C0C0C0',
            marginTop: '2%',
            marginBottom: '2%',
            paddingTop: '2%',
            
            '& .contentTop':{
                '& .rankWrapper':{
                    paddingLeft: '2%',
                    '& .rank':{
                        color: '#fffe',
                        background: '#f91818e0',
                        width: '30%',
                        border: '4px solid #ea9044',
                        borderRadius: '40px',
                    }
                },
                '& .qualification':{
                    '& .qualifyName':{
                        color: '#91230c',
                        width: '60%',
                        marginLeft: '25%',
                        marginTop: '-20%',
                        marginBottom: '12%',
                    }
                }
            },
            '& .imageWrapper':{
                position: 'relative',
                '& img':{
                    border: '2px solid #ea9044',
                    borderTopLeftRadius: '40px',
                    borderBottomRightRadius: '40px',
                }
            },
            '& .studentDetails':{
                paddingTop: '3%',
                paddingBottom: '2%',
                '& h4':{
                    color: '#ea9044',
                    '@media screen and (max-width: 767px)':{
                        fontSize: '1.4rem',
                    },
                },
                '& .position':{
                    '& p':{
                        color: '#8a8686',
                    }
                }
            }
        }
    }
}

const stu = StudentsList.reduce((student, current) =>{
    if(!student[current.exam]) student[current.exam] = [];
    student[current.exam].push(current);
  return student;
  },{});

class AchievementsList extends Component {
    render() {

        const {classes} = this.props;

        const studentList = Object.keys(stu).map((key, index) => <div key={key}>
        <div className="title">
            <h2>{key}</h2>
        </div>
            <Row className="cardWrapper">
            {
                stu[key].map((student, index) => 
                    <Col key={index} md="3">
                        <div className="studentCard">
                            <div className="contentTop">
                                <div className="rankWrapper">
                                    {(student.rank) ? <h5 className="rank">AIR<br/><strong>{student.rank}</strong></h5> : null}
                                </div>
                                <div className="qualification">
                                        {(student.qualification) ? <h5 className="qualifyName">{student.qualification}</h5> : null}
                                </div>
                            </div>
                            <div className="imageWrapper">
                                <Zoom>
                                    <img className="" src={student.img} width="80%" height="200px" alt={student.name}/>
                                </Zoom>
                            </div>
                            <div className="studentDetails">
                                        <h4>{student.name}</h4>
                                <div className="position">
                                    {(student.roll) ? <h6>Roll No. : {student.roll}</h6> : null}
                                    {(student.university) ? <p>{student.university}</p> : null}
                                    {(student.subject) ? <h6>{student.subject}</h6> : null}
                                </div>
                            </div>
                        </div>
                    </Col>)          
            }
            </Row>
        </div>)
        return (
            <div className={classes.achivement}>
                <h1>Achievements of Our Students</h1>
                <div className="container">
                  {studentList}
                {/*<div className="row flex-wrap">*/}
                {/*{students}*/}
                {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default injectsheet(styles)(AchievementsList);


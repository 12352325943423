import React, {Component, useState} from 'react';
import injectsheet from 'react-jss';
import {useForm} from "react-hook-form";
// import {DevTool} from "@hookform/devtools";
import {
    Row,
    Col
} from 'reactstrap'

const styles = {
    contact: {
        textAlign: 'left',
        position: 'relative',
        '& .jumbotron': {
            background: "url('/images/onlineClasses-min.jpg') no-repeat center",
            backgroundSize: 'contain',
            minHeight: '400px'
        },
        '& img': {},
        '& .contactWrapper': {
            paddingTop: '2%',
            paddingBottom: '2%',
            '@media screen and (max-width: 768px)': {
                '& h2': {
                    fontSize: '1.6rem',
                }
            },
            '& form': {
                marginLeft: '4%',
                paddingTop: '2%',
                '@media screen and (max-width: 768px)': {
                    paddingTop: '0',
                },
                '& .form-control:focus': {
                    borderColor: 'inherit',
                    boxShadow: 'none',
                },
                '& .btn': {}
            },
            '& h2': {
                color: '#ea9044',
                marginLeft: '4%',
                '& .textWrapper': {
                    borderRight: '2px solid',
                },
            },
            '& p': {
                fontSize: '1.2rem',
                '& a': {
                    color: '#000',
                }
            },
            '& .addressWrapper': {
                '@media screen and (max-width: 768px)': {
                    marginLeft: '3%',
                    '& h2': {
                        fontSize: '1.6rem',
                    },
                    '& p': {
                        fontSize: '1.0rem',
                    }
                },
            }
        }
    }
}

function RegistrationForm() {
    const {register,
        // control,
        handleSubmit,
        errors} = useForm({
        mode: "onChange",
    });
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formsErrors, setFormsErrors] = useState(null)
    const onSubmit = data => {
        setLoading(true)
        fetch('https://exam.vidyayatan.in/api/rest/liveclasses/register', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(json => {
                setLoading(false)
                setFormsErrors(null)
                setSuccess(false)
                if (json.success) {
                    return setSuccess(true)
                }
                setFormsErrors(json.errors)
            }).catch((error) => {
            setLoading(false)
            console.log(error);
        });
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                (formsErrors !== null && !loading) && <div className="errors">
                    {
                        Object.keys(formsErrors).map((key, i) =>
                            <div className="alert alert-danger" role="alert" key={i}>
                                {key + ":" + formsErrors[key]}
                            </div>
                        )
                    }
                </div>
            }
            <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input type="text" className={"form-control " + ((errors.name) && "is-invalid")} id="name" name="name"
                       aria-describedby="email"
                       placeholder="Please enter your Full Name" ref={register({required: true})}/>
                {errors.name &&
                <div className="invalid-feedback">Please enter your full name.</div>}

            </div>

            <div className="form-row">
                <div className="form-group col-12 col-sm-6">
                    <label htmlFor="email">Email address</label>
                    <input type="email" className={"form-control " + ((errors.email) && "is-invalid")} id="email"
                           name="email" aria-describedby="email"
                           placeholder="Enter email Address" ref={register({required: true})}/>
                    {errors.email &&
                    <div className="invalid-feedback">Please enter a valid Email Id.</div>}
                    <small id="emailHelp" className="form-text text-muted">Enter a valid E-Mail, this be used for
                        all Communications.</small>
                </div>

                <div className="form-group col-12 col-sm-6">
                    <label htmlFor="inputEmail1">Mobile Number</label>
                    <input type="tel" className={"form-control " + ((errors.mobile) && "is-invalid")} id="mobile"
                           name="mobile" aria-describedby="mobile"
                           placeholder="Enter your Mobile Number" ref={register({required: true})}/>
                    {errors.mobile &&
                    <div className="invalid-feedback">Please enter a Valid Number.</div>}
                    <small id="emailHelp" className="form-text text-muted">Enter a valid Mobile number, this be used for
                        all Communications.</small>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-12 col-sm-6">
                    <label htmlFor="inputEmail1">Date of Birth</label>
                    <input type="date" className={"form-control " + ((errors.dob) && "is-invalid")} id="dob" name="dob"
                           aria-describedby="dob"
                           placeholder="Date of Birth" ref={register({required: true})}/>
                    {errors.name &&
                    <div className="invalid-feedback">Please select your Date of Birth.</div>}
                </div>

                <div className="form-group col-12 col-sm-6">
                    <label htmlFor="gender">Gender</label>
                    <select className={"form-control " + ((errors.gender) && "is-invalid")} id="gender" name="gender"
                            ref={register({required: true})}>
                        <option value="" disabled selected>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Transgender</option>
                    </select>
                    {errors.gender &&
                    <div className="invalid-feedback">Please select your gender.</div>}
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-12 col-sm-6">
                    <label htmlFor="city">City</label>
                    <input type="text" className={"form-control " + ((errors.city) && "is-invalid")} id="city"
                           name="city" aria-describedby="mobile"
                           placeholder="Enter City Name" ref={register({required: true})}/>
                    {errors.city &&
                    <div className="invalid-feedback">Please enter your City.</div>}
                </div>

                <div className="form-group col-12 col-sm-6">
                    <label htmlFor="state">State</label>
                    <select name="state" id="state" className={"form-control " + ((errors.state) && "is-invalid")}
                            ref={register({required: true})}>
                        <option value="" disabled selected>Select</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                    </select>
                    {errors.state &&
                    <div className="invalid-feedback">Please select your state.</div>}
                </div>
            </div>

            <div className="text-center">
                <button type="submit" className="btn btn-primary" disabled={loading}>Submit</button>
            </div>
            {/*<DevTool control={control}/>*/}
            {
                success && <div className="alert alert-success" role="alert">
                    Your registration has been received. Your Registration Id has been sent to your Mobile Number.
                    For further steps please call <a className="btn btn-outline-primary"
                                                       href="tel:+91 7004600534">+91-7004600534</a>
                </div>
            }
        </form>
    );
}

class LiveClasses extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.contact}>
                <div>
                    <Row>
                        <Col>
                            <div className="jumbotron jumbotron-fluid">
                                <div className="container">
                                    <h1 className="display-4">Live Classes</h1>
                                    <p>Join Live sessions through Zoom</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="contactWrapper">
                        <Col md="1"></Col>
                        <Col md="5">
                            <h2>Register for Live Classes</h2>
                            <RegistrationForm/>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="5">
                            <div className="addressWrapper">
                                <h2>Steps</h2>
                                <ol>
                                    <li>Register yourself through the form.</li>
                                    <li>You will receive unique registration number on your mobile and email.</li>
                                    <li>Pay the fees for Live classes.</li>
                                    <li>Quote your Registration number when you call us on +91-7004-60-0534</li>
                                    <li>After confirmation you will receive joining link for live classes.</li>
                                    <li>Make sure to install zoom Application on you Mobile or PC.</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default injectsheet(styles)(LiveClasses);


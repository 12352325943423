import React, {Component} from 'react';
import injectsheet from 'react-jss';
import {
    Row,
    Col,
} from 'reactstrap'

const styles = {
    dwnlod: {
        '& .textStyle':{
            '& h2':{
                marginTop: '3%',
            }
        },
       '& h1':{
           marginLeft: '3%',
           marginTop: '5%',
           marginBottom: '2%',
           textAlign: 'left',
           color: '#ea9044',
           borderBottom: '1px solid #91230c',
           '@media screen and (max-width: 1068px)':{
            marginTop: '8%',
           },
           '@media screen and (max-width: 868px)':{
             marginTop: '10%',
            },
            '@media screen and (max-width: 556px)':{
             marginTop: '20%',
            },
            '@media screen and (max-width: 468px)':{
             marginTop: '25%',
            },
    },
        '& .dwnlodWrapper':{
            marginBottom: '2%',
            color: '#91230c',
        }
    }
}

class Download extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.dwnlod}>
                    <div  className="syllabus">
                        <h1>Syllabus</h1>
                        <Row>
                            <Col md="12">
                                <Row className="dwnlodWrapper">
                                    <Col md="5">
                                        <h4>Net Life Sciences Syllabus</h4>
                                    </Col>
                                    <Col md="2">
                                        <a className="btn btn-primary" target="_blank" rel="noopener" href="/pdf/NET-SYLLABUS.pdf">Download</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="12">
                                <Row className="dwnlodWrapper">
                                    <Col md="5">
                                        <h4>GATE Life Sciences Syllabus</h4>
                                    </Col>
                                    <Col md="2">
                                        <a className="btn btn-primary" target="_blank" rel="noopener noreferrer" href="/pdf/GATE_SYLLABUS.pdf">Download</a>
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                    </div>
                    <div className="textStyle">
                        <h1>JRF/NET Answer</h1>
                        <Row className="dwnlodWrapper">
                            <Col md="5">
                                <h4>Answer Key</h4>
                            </Col>
                            <Col md="2">
                                <a className="btn btn-primary" href="https://drive.google.com/open?id=1z6sNOi5IoXA5dTINTfMb2plUy5ubr4-m" target="_blank" rel="noopener noreferrer">Download</a>
                            </Col>
                        </Row>
                    </div>
                    <div className="textStyle">
                        <h1>Questions</h1>
                        <Row className="dwnlodWrapper">
                            <Col md="5">
                                <h4>Csir Questions</h4>
                            </Col>
                            <Col md="2">
                                <a className="btn btn-primary" href="https://drive.google.com/drive/folders/1z6sNOi5IoXA5dTINTfMb2plUy5ubr4-m" target="_blank" rel="noopener noreferrer">Download</a>
                            </Col>
                        </Row>
                    </div>
            </div>
        );
    }
}

export default injectsheet(styles)(Download);


import React, { Component } from 'react';
import injectsheet from 'react-jss'
import {
    Row,
    Col,
    Table,
} from 'reactstrap';

const styles={
    course:{
        '& h1':{
            marginTop: '6%',
            marginBottom: '2%',
            color: '#ea9044',
            '@media screen and (max-width: 767px)':{
                fontSize: '1.6rem',
            },
            '@media screen and (max-width: 1068px)':{
                marginTop: '8%',
               },
               '@media screen and (max-width: 868px)':{
                 marginTop: '10%',
                },
                '@media screen and (max-width: 556px)':{
                    marginTop: '20%',
                },
                '@media screen and (max-width: 468px)':{
                 marginTop: '25%',
                },
        },
        '& table':{
            marginBottom: '2%',
            '@media screen and (max-width: 767px)':{
                marginLeft: '5%',
                width: '90%',
               },
               '@media screen and (max-width: 467px)':{
                marginLeft: '5%',
                width: '90%',
               },
            '& th':{
                textAlign: 'center',
                color:'#91230c',
                '@media screen and (max-width: 767px)':{
                    fontSize: '0.9rem',
                },
                '@media screen and (max-width: 426px)':{
                    fontSize: '0.7rem',
                },
            },
            '& td':{
                '@media screen and (max-width: 767px)':{
                    fontSize: '0.9rem',
                },
                '@media screen and (max-width: 426px)':{
                    fontSize: '0.7rem',
                },
            }
        }
    }
}

class Courses extends Component {
  render() {
      const { classes } = this.props
    return (
            <div className={classes.course}>
            <h1>Net In Life Science</h1>
                <Row>
                    <Col md="2"></Col>
                    <Col>
                    <Table bordered>
                        <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Courses</th>
                            <th>Duration</th>
                            <th>Fee Structure (Including S.T.)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>JRF-NET-Classroom Program ( for M.sc. Appearing students)</td>
                            <td>10 months</td>
                            <td>&#x20B9; 35000/-</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>JRF-NET-Classroom Program ( for M.sc. Passed students)</td>
                            <td>8 months</td>
                            <td>&#x20B9; 35000/-</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>GATE-Classroom Program ( for M.sc. Passed students)</td>
                            <td>10 months</td>
                            <td>&#x20B9; 30000/-</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>GATE-Classroom Program ( for M.sc. Passed students)</td>
                            <td>8 months</td>
                            <td>&#x20B9; 30000/-</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Test Series (offline)</td>
                            <td></td>
                            <td>&#x20B9; 3000/- for 10 Tests mock test</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Online Test Series</td>
                            <td></td>
                            <td>&#x20B9; 1000/- for 15 Test Papers including mock test</td>
                        </tr>
                        </tbody>
                    </Table>
                    </Col>
                    <Col md="2"></Col>
                </Row>
            </div>
      
    );
  }
}

export default injectsheet(styles) (Courses);

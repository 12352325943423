import React, { Component } from 'react';
import './App.css';
import NavbarTop from './component/NavbarTop'
import Footer from './component/Footer'
import AchievementsList from './pages/AchievementsList'
import Books from './pages/Books'
import Courses from './pages/Courses'
import Results from './pages/Results'
import Download from './pages/Download'
import ContactUs from './pages/ContactUs'
import LiveClasses from './pages/LiveClasses'
import Loadable from 'react-loadable'
import { BrowserRouter as Router, Route } from 'react-router-dom'


function Loading({ error }) {
    if (error) {
        return '';
    } else {
        return <h3>Loading...</h3>;
    }
}

const Home = Loadable({
    loader: () => import('./pages/home'/* webpackChunkName: "home" */),
    loading: Loading
});

const Gallery = Loadable({
    loader: () => import('./pages/Gallery' /* webpackChunkName: "gallery" */),
    loading: Loading
});


class App extends Component {
  render() {
    return (
      <div className="App">
          <Router>
            <div>
            <NavbarTop/>
              <Route exact path="/" component={Home} />
              <Route path="/achievements" component={AchievementsList} />
              <Route path="/courses" component={Courses} />
              <Route path="/results" component={Results} />
              <Route path="/books" component={Books} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/download"  component={Download}/>
              <Route path="/contact" component={ContactUs} />
              <Route path="/live_classes" component={LiveClasses} />
            <Footer/>
            </div>
          </Router>
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react';
import injectsheet from 'react-jss'
import {
    Row,
    Col,
    Table,
} from 'reactstrap';

const styles={
    course:{
        '& h1':{
            marginTop: '6%',
            marginBottom: '2%',
            color: '#ea9044',
            '@media screen and (max-width: 767px)':{
                fontSize: '1.6rem',
            },
            '@media screen and (max-width: 1068px)':{
                marginTop: '8%',
               },
               '@media screen and (max-width: 868px)':{
                 marginTop: '10%',
                },
                '@media screen and (max-width: 556px)':{
                    marginTop: '20%',
                },
                '@media screen and (max-width: 468px)':{
                 marginTop: '25%',
                },
        },
        '& table':{
            marginBottom: '2%',
            textAlign: 'left',
            '@media screen and (max-width: 767px)':{
                marginLeft: '5%',
                width: '90%',
               },
               '@media screen and (max-width: 467px)':{
                marginLeft: '5%',
                width: '90%',
               },
            '& th':{
                textAlign: 'center',
                color:'#91230c',
                fontSize: '1.2rem',
                '@media screen and (max-width: 767px)':{
                    fontSize: '0.9rem',
                },
                '@media screen and (max-width: 426px)':{
                    fontSize: '0.7rem',
                },
            },
            '& td':{
                '@media screen and (max-width: 767px)':{
                    fontSize: '0.9rem',
                },
                '@media screen and (max-width: 426px)':{
                    fontSize: '0.7rem',
                },
            },
            '& tr td tr':{
                '& td':{
                    border: '0',
                }
            },
            '& .fontBold':{
                fontWeight: 'bold',
            }
        },
        '& ul':{
            listStyle: 'none',
            margin: '0',
            padding: '0',
            '& li':{
                marginBottom: '2%',
                '& a':{
                    '& i':{
                        position: 'relative',
                        top: '6px'
                    }
                }
            }
        },
        '& .material-icons':{
            fontSize: '20px',
        }
    }
}

class Books extends Component {
  render() {
      const { classes } = this.props
    return (
            <div className={classes.course}>
            <h1>LIFE SCIENCES: BOOK LIST</h1>
                <Row>
                    <Col md="2"></Col>
                    <Col>
                    <Table bordered>
                        <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>topics</th>
                            <th>Books</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Molecules and their interaction relevant to biology</td>
                            <td>
                                <ul>
                                    <li>Lehninger Principles of Biochemistry – <a href="https://drive.google.com/open?id=0BwFjZUIWAYjqX1V1TDZMX0lvbXM" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Nelson and Cox</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Biochemistry - <a href="https://drive.google.com/open?id=1NsRnaO8DN36GDFD_P8eu_JfX9NbDrTaJ" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Stryer</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Biochemistry – <a href="https://drive.google.com/open?id=1H53vj3Q2s2v36j7WhzvJYoPFN7qVsA1W" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Voet and Voet</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Biochemistry – <a  href="https://drive.google.com/open?id=1tvqYq0XLzw9hKEUkL8zdUR_3wNMrvOas" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Garrerr and Grishm</span> <i className="material-icons"> get_app</i></a></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Cellular Organization</td>
                            <td>
                                <ul>
                                    <li>Molecular Biology of the Cell - <a href="https://drive.google.com/open?id=13wnORnRIDPE8RtJlLndK9hbOPgGQ2m9N" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Alberts et. al.</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Cell and Molecular Biology - <a href="https://drive.google.com/open?id=1X4qhCuQTjU_k81ZiCyls6P8HhNEzT59U" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Karp</span> <i className="material-icons"> get_app</i></a> </li>
                                    <li>Molecular Cell Biology - <a href="https://drive.google.com/open?id=1YiVFdmyUMZX7o9V4pZXsPRXykSLkVVkI" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Lodish et. al.</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>The Cell – <a href="https://drive.google.com/open?id=1aLNbbBG62HK2kpt3JdJhVOXN3bV-BmEt" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Cooper and Hausman</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Cell and Molecular Biology - <span className="fontBold">De Roberties</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Fundamental Processes</td>
                            <td>
                                <ul>
                                    <li>Molecular Biology of the Gene - <a href="https://drive.google.com/open?id=1BKFrmLJZb5Y3RTZiYyH-NYVI8mwDj1ZD" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Watson et. al.</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Gene XII - <a href="https://drive.google.com/open?id=1KM5cLFvwnR-46JptMiPpZJHP8-DaPluE" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Lewin</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Molecular Biology and Biotechnology - <a href="https://drive.google.com/open?id=1jahkENImJdSv5uPucK6xzIlzthLcv7xv" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Walker and Rapley</span> <i className="material-icons"> get_app</i></a></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Cell Communication and Cell Signaling</td>
                            <td>
                                <ul>
                                    <li>Molecular Biology of the Cell - <span className="fontBold">Alberts et. al.</span></li>
                                    <li>Cell and Molecular Biology - <span className="fontBold">Karp</span></li>
                                    <li>Molecular Cell Biolgy - <span className="fontBold">Lodish et. al.</span></li>
                                    <li>The Cell – <span className="fontBold">Cooper and Hausman</span></li>
                                    <li>Lehninger Principles of Biochemistry – <span className="fontBold">Nelson and Michael</span></li>
                                    <li>Molecular Biology of Cancer - <span className="fontBold">Pecorino</span></li>
                                    <li>Immunology - <a href="https://drive.google.com/open?id=15YCGPZpUlpvvyPdXSRgo9th5Vtpxx7vu" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Kuby</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Roitt’s Essential Immunology - <a href="https://drive.google.com/open?id=1t9MxoQa0vLdKujZIzVyht0l4s0dKfBuO" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Delves et. al.</span> <i className="material-icons"> get_app</i></a></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Developmental Biology</td>
                            <td>
                                <ul>
                                    <li>Developmental Biology – <a href="https://drive.google.com/open?id=1WSef7m88LNc_Q7S1VP6smYuRpN9Znvpe" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Gilbert</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>Developmental Biology – <a href="https://drive.google.com/open?id=15LRi0YHYXZFQO71GQIOa7wMhhQ-okeN-" target="_blank" rel="noopener noreferrer"> <span className="fontBold">Hodge</span> <i className="material-icons"> get_app</i></a></li>
                                    <li>An Introduction of Embryology - <span className="fontBold">Balinsky</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>System Physiology-Plants</td>
                            <td>
                                <ul>
                                    <li>Plant Physiology - <a href="https://drive.google.com/open?id=1EJM18esitmC-3VJDid0btvNxXaoljRMw" target="_blank" rel="noopener noreferrer"><span className="fontBold">Taiz and Zeiger</span><i className="material-icons"> get_app</i></a></li>
                                    <li>Plant Physiology - <span className="fontBold">Ordog</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>System Physiology-Animals</td>
                            <td>
                                <ul>
                                    <li>Textbook of Medical Physiology – <span className="fontBold">Gyton & Hall</span></li>
                                    <li>Ganong’s review of Medical Physiology - <span className="fontBold">Barrett et. al.</span></li>
                                    <li>Human Physiology - <span className="fontBold">Luciano</span></li>
                                    <li>Williams Textbook of Endocrinology - <span className="fontBold">Kronenberg et. al.</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Inheritance Biology</td>
                            <td>
                                <ul>
                                    <li>Genetics- Analysis & Principles - <span className="fontBold">Brooker</span></li>
                                    <li>Genetics- A Conceptual Approach - <span className="fontBold">Benjamin A. Pierce</span></li>
                                    <li>Genetics - <span className="fontBold">Strickberger</span></li>
                                    <li>Principles of Genetics - <a href="https://drive.google.com/open?id=1ym4b5kMUvP3hTiC81w0SAAs1lCYjfc3O" target="_blank" rel="noopener noreferrer"><span className="fontBold">Tamerin</span><i className="material-icons"> get_app</i></a></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Diversity of Life Forms</td>
                            <td>
                                <ul>
                                    <li>Books of plant taxonomy</li>
                                    <li>Books of animal classification</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">10</th>
                            <td>Ecological Principles</td>
                            <td>
                                <ul>
                                    <li>Concepts of Ecology – <span className="fontBold">Odem</span></li>
                                    <li>Ecology and Biodiversity – <span className="fontBold">Magurran</span></li>
                                    <li>Plant Ecology - <span className="fontBold">Beck</span></li>
                                    <li>Community Ecology - <span className="fontBold">Morin</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">11</th>
                            <td>Evolution and Behaviour</td>
                            <td>
                                <ul>
                                    <li>Evolution – <span className="fontBold">Strickberger</span></li>
                                    <li>Evolution - <span className="fontBold">Futuyama</span></li>
                                    <li>Evolution - <span className="fontBold">Ridley</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">12</th>
                            <td>Applied Biology</td>
                            <td>
                                <ul>
                                    <li>Molecular Biology and Biotechnology - <span className="fontBold">Walker and Rapley</span></li>
                                    <li>Stem Cell Biology - <span className="fontBold">Gardner</span></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">13</th>
                            <td>Methods in Biology</td>
                            <td>
                                <ul>
                                    <li>Real Time PCR – <span className="fontBold">Dorak</span></li>
                                    <li>Principles and Techniques of Biochemistry and Molecular Biology – <span className="fontBold">Wilson and Walker</span></li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    </Col>
                    <Col md="2"></Col>
                </Row>
            </div>
      
    );
  }
}

export default injectsheet(styles) (Books);

import React from 'react';
import injectsheet from 'react-jss';
import { Link } from "react-router-dom";
import { Collapse, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown } from 'reactstrap';

const styles = {
    navbar:{
        padding: '0 1rem',
        borderBottom: '1px solid #d0c8c8',
      '&.navbar-light .navbar-nav .nav-item':{
        color: '#ea9044',
          '&:not(:last-child)':{
              borderRight: '1px solid #ea9044',
          }
      },
      '&.navbar-light .navbar-nav .nav-link:hover':{
        color: '#91230c',
        background: '#ea9044',
        borderRadius: '15px',
      },
      '& .nav-link':{
        padding: '0',
          whiteSpace: 'nowrap',
      },
      '& .dropdown-menu':{
        minWidth: '8rem',
        textAlign: 'center',
      },
        '& a.examLink':{
            position: 'relative',
            color: '#fff',
            border: '1px solid #9044ea',
            borderRadius: '2px',
            padding: '.2rem 20px',
            background: '#9044ea',
            transition: '.3s ease all',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            '&.live_classes':{
                border: '1px solid #3497da',
                background: '#3497da',
                marginRight: '.5rem',
                '&:hover':{
                    background: 'none',
                    color: '#3497da',
                    '&:after':{
                        right: '0',
                        opacity: '0'
                    }
                }
            },
            '&:hover':{
                background: 'none',
                color: '#9044ea',
                '&:after':{
                    right: '0',
                    opacity: '1'
                }
            },
            '&:after':{
                position: 'absolute',
                fontFamily: 'Material Icons',
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontSize: '20px',
                lineHeight: '1',
                color: '#9044ea',
                right: '8px',
                letterSpacing: 'normal',
                textTransform: 'none',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                wordWrap: 'normal',
                direction: 'ltr',
                content: "'send'",
                opacity: 0,
                transition: '.3s ease all',
            }
        }
    }
}

class NavbarTop extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const {classes} = this.props;
    return (
        <Navbar color="light" light className={classes.navbar + " fixed-top"} expand="md">
          <NavbarBrand href="/">
            <img src="/images/logo.png" width="60%" alt="logo"/>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle}/>
          <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <li className="nav-item">
                  <Link className="nav-link"  to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/achievements">Achievements</Link>
                </li>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                  Academics
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link className="nav-link" to="/courses">
                      Courses
                    </Link>
                    <Link className="nav-link" to="/results">
                      Results
                    </Link>
                    <Link className="nav-link" to="/books">
                      Books
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="nav-item">
                  <Link className="nav-link" to="/gallery">Photo Gallery</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link"  to="/download">Download</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact Us</Link>
                </li>
              </Nav>
          </Collapse>
            <Link to="live_classes" className="examLink live_classes">Live Classes</Link>
            <a href="https://exam.vidyayatan.in" target="_blank" rel="noopener noreferrer" className="examLink">Online Exam
                {/*<i className="material-icons">*/}
                {/*send*/}
                {/*</i>*/}
            </a>
        </Navbar>
    );
  }
}

export default injectsheet(styles)(NavbarTop);
import React, { Component } from "react";
import injectsheet from "react-jss";
import { Row, Col } from "reactstrap";
const result = require('../data/RESULT_DETAILS.pdf')

const { innerWidth: width, innerHeight: height } = window;

const styles = {
  result: {
    "& h1": {
      marginTop: "6%",
      marginBottom: "2%",
      color: "#ea9044",
      "@media screen and (max-width: 767px)": {
        fontSize: "1.6rem"
      },
      "@media screen and (max-width: 1068px)": {
        marginTop: "8%"
      },
      "@media screen and (max-width: 868px)": {
        marginTop: "10%"
      },
      "@media screen and (max-width: 556px)": {
        marginTop: "20%"
      },
      "@media screen and (max-width: 468px)": {
        marginTop: "25%"
      }
    },
    "& table": {
      marginBottom: "2%",
      "@media screen and (max-width: 767px)": {
        marginLeft: "5%",
        width: "90%"
      },
      "@media screen and (max-width: 467px)": {
        marginLeft: "5%",
        width: "90%"
      },
      "@media screen and (max-width: 426px)": {
        marginLeft: "5%",
        width: "90%"
      },
      "& th": {
        textAlign: "center",
        color: "#91230c",
        "@media screen and (max-width: 767px)": {
          fontSize: "0.9rem"
        },
        "@media screen and (max-width: 426px)": {
          fontSize: "0.7rem"
        }
      },
      "& td": {
        "@media screen and (max-width: 767px)": {
          fontSize: "0.9rem"
        },
        "@media screen and (max-width: 426px)": {
          fontSize: "0.7rem"
        }
      }
    }
  }
};

class Results extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.result}>
        <h1>Results</h1>
        <Row>
          <Col md={{ size: 12}}>
              <embed src={result} width={width*0.8} height={height*1.2} alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectsheet(styles)(Results);

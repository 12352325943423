import React, {Component} from 'react';
import injectsheet from 'react-jss';
import {
    Row,
    Col,
    Button, 
    Form,
      Label, 
      Input, 
} from 'reactstrap'

const styles = {
    contact: {
        textAlign: 'left',
        position: 'relative',
        '& img':{
            
        },
        '& .contactWrapper':{
            paddingTop: '2%',
            paddingBottom: '2%',
            '@media screen and (max-width: 768px)':{
                '& h2':{
                    fontSize: '1.6rem',
                }
              },
            '& form':{
                marginLeft:'4%',
                paddingTop: '2%',
                '@media screen and (max-width: 768px)':{
                    paddingTop: '0',
                  },
                '& .form-control:focus':{
                    borderColor: 'inherit',
                    boxShadow: 'none',
                },
                '& .btn':{
                    marginLeft: '22%',
                }
            },
            '& h2':{
                color: '#ea9044',
                marginLeft:'4%',
                '& .textWrapper':{
                    borderRight: '2px solid',
                },
            },
            '& p':{
                fontSize: '1.2rem',
                '& a':{
                    color: '#000',
                }
            },
            '& .addressWrapper':{
                '@media screen and (max-width: 768px)':{
                    marginLeft:  '3%',
                    '& h2':{
                        fontSize: '1.6rem',
                    },
                    '& p':{
                        fontSize: '1.0rem',
                    }
                  },
            }
        }
    }
}

class ContactUs extends Component {
    // onSubmit = async data => {
    //     console.log(data)
    //     await setSubmitting(true)
    //     const url = 'https://theroguepixxel.com/waapi/contact_submission'
    //     const requestOptions = {
    //         method: 'POST',
    //         mode: "no-cors",
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    //         body: Object.entries(data).map(([k,v])=>{return k+'='+v}).join('&')
    //     };
    //     await fetch(url, requestOptions)
    //         .then(async response => {
    //             await setSubmitting(false)
    //             await setFormSubmitted(true)
    //         })
    //         .catch(async error => {
    //             await setSubmitting(false)
    //         })
    // }
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.contact}>
                <div>
                    <Row><img src="./images/contactUs.jpg" width="100%" alt="1-life-sciences"/></Row>
                    <Row className="contactWrapper">
                        <Col md="1"></Col>
                        <Col md="5">
                        <h2> <span className="textWrapper"> Email &nbsp;</span>&nbsp;Query</h2>
                        <Form name="frmregistration" id="frmregistration" method="post" action="">
                            <div className="form-group row">
                            <Label htmlFor="fname" className="col-sm-2 col-form-label">Full Name</Label>
                            <div className="col-sm-10">
                                <Input type="text" className="form-control" name="fname" id="fname" />
                            </div>
                            </div>
                            <div className="form-group row">
                            <Label htmlFor="courses" className="col-sm-2 col-form-label">Courses</Label>
                            <div className="col-sm-10">
                                <div><select name="courses" id="courses" className="form-control selectpicker" tabIndex={-98}>
                                    <option value>Please Select</option> 
                                    <option value="M.Sc. Biotech | Life Sciences  ">M.Sc. Biotech | Life Sciences </option>
                                    <option value="CSIR-NET Life Sciences">CSIR-NET Life Sciences</option>
                                    <option value="GATE Biotechnology">GATE Biotechnology</option>
                                    <option value="GATE Life Sciences">GATE Life Sciences</option> 
                                </select></div>
                            </div>
                            </div>
                            <div className="form-group row">
                            <Label htmlFor="programes" className="col-sm-2 col-form-label">Programmes</Label>
                            <div className="col-sm-10">
                                <div><select name="programes" id="programes" className="form-control selectpicker" tabIndex={-98}> 
                                    <option value>Please Select</option> 
                                    <option value="Classroom Programme">Classroom Programme</option> 
                                    <option value="Correspondence Programme">Correspondence Programme</option>
                                    <option value="Test Series (Postal)">Test Series (Postal)</option> 
                                    <option value="Books">Books</option> 
                                </select></div>
                            </div>
                            </div>
                            <div className="form-group row">
                            <Label htmlFor="address" className="col-sm-2 col-form-label">Address</Label>
                            <div className="col-sm-10">
                                <Input type="text" className="form-control" name="address" id="address" />
                            </div>
                            </div>
                            <div className="form-group row">
                            <Label htmlFor="email" className="col-sm-2 col-form-label">Email</Label>
                            <div className="col-sm-10">
                                <Input type="text" className="form-control" name="email" id="email" />
                            </div>
                            </div>
                            <div className="form-group row">
                            <Label htmlFor="contact" className="col-sm-2 col-form-label">Contact No</Label>
                            <div className="col-sm-10">
                                <Input type="text" className="form-control" name="contact" id="contact" />
                            </div>
                            </div>
                            <div className="form-group row">
                            <Label htmlFor="comments" className="col-sm-2 col-form-label">Comments</Label>
                            <div className="col-sm-10">
                                <textarea className="form-control" name="comments" id="comments" />
                            </div>
                            </div>
                            
                            <div className="form-group row">
                            <div className="col-sm-10">
                                <Button type="button" name="btnsubmit" id="btnsubmit" className="btn btn-primary">Submit</Button>
                                <Input type="hidden" name="frmtype" id="frmtype" defaultValue="contactform" />
                            </div>
                            </div>
                        </Form>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="5">
                            <div className="addressWrapper">
                                <h2>Address</h2>
                                
                                <p> <i className="fa fa-home" aria-hidden="true"></i>&nbsp;  Net In Life Science, Vidyayatan<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Balika Shiksha Sadan, Near Apsara Hotel<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Circular Road Ranchi, Jharkhand<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;834001, India<br/>
                                <span><i className="fa fa-phone"> </i>&nbsp; <a href="tel:+91-7004-60-0534">(+91) 7004600534</a></span></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default injectsheet(styles)(ContactUs);


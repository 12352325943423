import React, {Component} from 'react';
import injectsheet from 'react-jss';
import {
    Row,
    Col,
} from 'reactstrap';
import Social from '../Social'

const styles = {
    footer: {
        textAlign: 'center',
        '& a':{
            color: '#fff',
        },
        '& i':{
            color: '#fff',
            marginBottom: '2%'
        },
        '& .footerTop':{
            backgroundColor: '#353e3e',
            paddingTop: '2%',
            paddingBottom: '2%',
            '& h4':{
                color: '#fbbf31',
                paddingBottom: '3%',
            },
            '& p':{
                color:'#fff',
                marginLeft: '5%',
            },
        },
        '& .footerBottom':{
            background: '#353e3e',
            paddingTop: '2%',
            paddingBottom: '1%',
            borderTop: '1px solid #504d4d',
            '& a':{
                fontFamily: ' "Roboto", sans-serif',
                color: '#fffe',
                transition: 'color .3s ease', 
                '& small': {
                    color: '#52b43c',
                  },
            },
            '& a:hover': {
                color: '#52b43c',
                textDecoration: 'none',
               },
            '& a:hover small': {
                color: '#fffe',
            },
            '& p':{
                color: '#A1A7AD',
            },
            '& img':{
                '@media screen and (max-width: 768px)':{
                    width: '90%',
                  },
            }
        } 
    }
}

class Footer extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.footer}>
                <Row className="footerTop">
                    <Col md="4">
                        <h4>Ranchi</h4>
                        <p>VIDYAYATAN, Balika Shiksha Sadan,<br/> Circular Road, Ranchi <br/>Jharkhand 834001 (India)</p>
                    </Col>
                    <Col md="4">
                        <h4>Contact With Us</h4>
                        <span><i className="fa fa-phone"> </i>&nbsp; <a href="tel:+91-7004-60-0534">(+91) 7004600534</a></span><br/>
                        <span><i className="fa fa-envelope"> </i>&nbsp; <a href="mailto:vidyayatan@netinlifesciences.com">vidyayatan.in@gmail.com</a></span>
                    </Col>
                    <Col md="4">
                        <h4>Social Media</h4>
                        <Social/>
                    </Col>
                </Row>
                <Row className="footerBottom">
                    <Col md="5">
                        <p>Copyright © 2022. All Rights Reserved.</p>
                    </Col>
                    <Col md="2">
                        <img src="/images/logo.png" width="100%" alt="logo"/>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="4">
                        <p>
                            Developed at  <a href="http://theroguepixel.com" target="_blank" rel="noopener noreferrer">the, rogue Pix<small>x</small>el</a>
                        </p>
                        
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectsheet(styles)(Footer);


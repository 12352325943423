import React,{Component} from 'react';
import injectsheet from 'react-jss';

const styles ={
    social:{
        marginTop: '10%',
        opacity: '0.8',
        textAlign: 'center',
        '@media screen and (max-width: 767px)':{
            marginTop: '5%',
        },
        '& a':{
            padding: '10px',
            '& .icon-bg':{
                color: '#004da0',
            },
            '& i':{
                color: '#fffe',
                '&:hover':{
                    backgroundColor: '#000',
                    color: '#ea9044',
                },
            }
        },
    }
}

class Social extends Component{
    render(){
        const {classes}=this.props;
        return(
            <div className={classes.social}>
                <a href="https://www.facebook.com/vidyaytan/" target="_blank" rel="noopener noreferrer">
                <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x icon-bg"></i>
                    <i className="fa fa-facebook fa-stack-1x" aria-hidden="true"></i>
                </span>
                </a>
                {/*<a href="#" target="_blank" rel="noopener noreferrer">*/}
                <a href="https://www.facebook.com/vidyaytan/" target="_blank" rel="noopener noreferrer">
                <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x icon-bg"></i>
                    <i className="fa fa-instagram fa-stack-1x"  aria-hidden="true"></i>
                </span>
                </a>
                {/*<a href="#" target="_blank" rel="noopener noreferrer">*/}
                <a href="https://www.facebook.com/vidyaytan/" target="_blank" rel="noopener noreferrer">
                    <span className="fa-stack fa-lg">
                        <i className="fa fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa fa-google-plus fa-stack-1x" aria-hidden="true"></i>
                    </span>
                </a>        
            </div>
        );
    }
}

export default injectsheet(styles)(Social);